export function button() {

  if(document.querySelector('.j-button') != null){
    const button = document.querySelector('.j-button');
    const button_group = document.querySelector('.j-btn_group');
    const button_second = document.querySelector('.j-button_second');
    const form_group = document.querySelector('.j-form-group');
    
    button.addEventListener('click', ()=>{
      if(button_group.classList.contains('active')){
        button_group.classList.remove('active');
      }else {
        button_group.classList.add('active');
      }

      if(form_group.classList.contains('active')){
        form_group.classList.remove('active');
      }else {
        form_group.classList.add('active');
      }
    });

    button_second.addEventListener('click', ()=>{
        button_group.classList.remove('active');
        form_group.classList.remove('active');
    });
  };


}