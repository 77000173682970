export function accordion() {

  const title = document.querySelectorAll('.j-AccordionTitle');
  
  title.forEach(titleEach => {
      let content = titleEach.nextElementSibling
      titleEach.addEventListener('click', () => {
      titleEach.classList.toggle('is-active')
      content.classList.toggle('is-open')
      })
  });
    
}

