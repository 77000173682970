import { header } from './modules/header';
header();
import { accordion } from './modules/accordion';
accordion();
import { button } from './modules/button';
button();
import { modal } from './modules/modal';
modal();
import { vh100 } from './modules/vh100';
vh100();
import { loaded } from './modules/loaded';
loaded();